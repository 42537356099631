"use client";

import { FC } from "react";

type Props = {};

export const CurrentYear: FC<Props> = () => {
	const currentYear = new Date().getFullYear();

	return currentYear;
};
