"use client";

import { motion } from "framer-motion";
import { Link } from "@nextui-org/link";
import { FC, useCallback } from "react";
import { Button } from "@nextui-org/button";
import { MobileNav } from "./MobileNav";
import { useMobileMenu } from "./MobileMenuContext";

type Props = {};

export const MobileMenu: FC<Props> = ({}) => {
	const { isMobileMenuOpen, setIsMobileMenuOpen } = useMobileMenu();

	const closeMobileMenu = useCallback(() => {
		setIsMobileMenuOpen(false);
	}, [setIsMobileMenuOpen]);

	return (
		<motion.div
			initial={{ height: 0 }}
			animate={{
				height: isMobileMenuOpen ? "auto" : 0,
			}}
			className="col-span-2 overflow-hidden md:hidden"
		>
			<div className="flex flex-col items-center gap-y-6 py-6">
				<MobileNav closeMobileMenu={closeMobileMenu} />

				<Button
					as={Link}
					href="/book"
					color="primary"
					fullWidth
					onClick={closeMobileMenu}
				>
					Book now
				</Button>
			</div>
		</motion.div>
	);
};
