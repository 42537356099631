"use client";

import {
	createContext,
	useContext,
	useState,
	FC,
	ReactNode,
	Dispatch,
	SetStateAction,
} from "react";

type MobileMenuContextType = {
	isMobileMenuOpen: boolean;
	setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileMenuContext = createContext<MobileMenuContextType | undefined>(
	undefined,
);

export const MobileMenuProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	return (
		<MobileMenuContext.Provider
			value={{ isMobileMenuOpen, setIsMobileMenuOpen }}
		>
			{children}
		</MobileMenuContext.Provider>
	);
};

export const useMobileMenu = (): MobileMenuContextType => {
	const context = useContext(MobileMenuContext);
	if (!context) {
		throw new Error("useMobileMenu must be used within a MobileMenuProvider");
	}
	return context;
};
