"use client";

import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { usePathname } from "next/navigation";
import { useMobileMenu } from "./MobileMenuContext";

type Props = { children?: ReactNode };

export const Wrapper: FC<Props> = ({ children }) => {
	const { isMobileMenuOpen } = useMobileMenu();

	const pathname = usePathname();

	const { scrollY } = useScroll();
	const [scrollYTarget, setScrollYTarget] = useState(Infinity);
	const headerRef = useRef<HTMLElement>(null);

	const updateScrollYTarget = useCallback(() => {
		const headerElement = headerRef.current;
		if (headerElement) {
			const newTargetScrollY = window.innerHeight - headerElement.offsetHeight;
			setScrollYTarget(newTargetScrollY);
		}
	}, []);

	useEffect(
		() => {
			updateScrollYTarget();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		const handleResize = () => updateScrollYTarget();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [updateScrollYTarget]);

	const transformedScrollY = useTransform(scrollY, [0, scrollYTarget], [0, 1]);

	const isHomePage = pathname === "/";
	const headerBackgroundOpacity =
		!isHomePage || isMobileMenuOpen ? 1 : transformedScrollY;

	return (
		<motion.header
			ref={headerRef}
			className="fixed z-50 flex w-full items-center border-b border-b-background-200 border-opacity-0 bg-white transition-all"
			style={{
				// @ts-ignore
				"--tw-bg-opacity": headerBackgroundOpacity,
				"--tw-border-opacity": headerBackgroundOpacity,
			}}
		>
			{children}
		</motion.header>
	);
};
