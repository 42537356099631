import { LinkProps } from "@nextui-org/link";

export const HEADER_LINKS: LinkProps[] = [
	{
		href: "/gallery",
		children: "Gallery",
	},
	{
		href: "/pricing",
		children: "Pricing",
	},
	{
		href: "/contact",
		children: "Contact",
	},
	{
		href: "/blog",
		children: "Blog",
	},
];
