"use client";

import { FC, useCallback } from "react";
import { Button } from "@nextui-org/button";
import { TbMenuDeep, TbX } from "react-icons/tb";
import { useMobileMenu } from "./MobileMenuContext";

type Props = {};

export const MobileMenuButton: FC<Props> = ({}) => {
	const { isMobileMenuOpen, setIsMobileMenuOpen } = useMobileMenu();

	const toggleMobileMenuIsOpen = useCallback(
		() => setIsMobileMenuOpen((prevValue) => !prevValue),
		[setIsMobileMenuOpen],
	);

	return (
		<Button
			isIconOnly
			variant="light"
			onClick={toggleMobileMenuIsOpen}
			aria-label={isMobileMenuOpen ? "Close mobile menu" : "Open mobile menu"}
		>
			{isMobileMenuOpen ? (
				<TbX className="h-6 w-6" />
			) : (
				<TbMenuDeep className="h-6 w-6" />
			)}
		</Button>
	);
};
