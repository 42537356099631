import { FC } from "react";
import { HEADER_LINKS } from "./constants";
import { Link } from "@nextui-org/link";

type Props = { closeMobileMenu: () => void };

export const MobileNav: FC<Props> = ({ closeMobileMenu }) => {
	return (
		<nav>
			<ul className="flex flex-col items-center gap-y-6">
				{HEADER_LINKS.map((linkProps, i) => (
					<li key={i}>
						<Link
							size="lg"
							color="foreground"
							onClick={closeMobileMenu}
							{...linkProps}
						/>
					</li>
				))}
			</ul>
		</nav>
	);
};
